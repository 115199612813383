<template>
  <div>
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-input
          color="dark"
          class="mx-1 my-0"
          type="date"
          size="small"
          label="Data de Emissão"
          v-model="filtro.data_emissao"
        ></vs-input>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Empresa</label
          >
          <br />
          <el-select
            size="small"
            filterable
            clearable
            placeholder="Empresa"
            v-model="filtro.id_empresa"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="empresa in empresas"
              :value="empresa.id"
              :label="empresa.razao_social"
              :key="empresa.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Tipo Pessoa</label
          >
          <br />
          <el-select
            filterable
            clearable
            size="small"
            :popper-append-to-body="false"
            v-model="filtro.tipo_pessoa"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="tipo in tipo_pessoa"
              :value="tipo.value"
              :label="tipo.nome"
              :key="tipo.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <vs-input
            size="small"
            class="mx-2"
            type="text"
            label="Busca (Cliente ou NF)"
            v-model="filtro.busca"            
            @keyup.enter="getBoletos()"
          ></vs-input>
        </div>
        <el-button
          size="small"
          type="primary"
          class="mx-1"
          plain
          @click="getBoletos()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <div
      id="tabelaBoletos"
      max-items="10"
      pagination
      class="cardTable mb-1 vs-con-loading__container"
    >
      <div
        class="mt-1"
        v-if="notasBoletos.length == undefined || notasBoletos.length == 0"
      >
        <div class="pt-20 scroll-boleto con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">Nenhum Boleto</h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="mt-1 scroll-boleto">
        <!-- <VuePerfectScrollbar class="scroll-boleto" :settings="settings"> -->
        <vs-table
          class="px-2"
          maxHeight="72vh"
          :data="notasBoletos"
          max-items="120"
          stripe
          id="tableBoletos"
          multiple
          color="success"
          v-model="selected"
        >
          <template slot="thead">
            <vs-th sort-key="id" style="width: 7%" id="center">Nota</vs-th>
            <vs-th sort-key="id" style="width: 7%" id="center">Contrato</vs-th>
            <vs-th style="width: 15%">Cliente</vs-th>
            <vs-th style="width: 12%">Empresa</vs-th>
            <vs-th id="center" style="width: 12%">Valor Líquido</vs-th>
            <vs-th id="center" style="width: 12%">Valor Bruto</vs-th>
            <vs-th id="center" style="width: 12%">Emissão</vs-th>
            <vs-th sort-key="qtd_boletos" style="width: 10%" id="center"
              >N° Boletos</vs-th
            >
            <vs-th sort-key="acoes" style="width: 10%">Ações</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
              <vs-td
                :data="tr.id"
                class="text-center font-semibold"
                style="font-size: 12px"
                >{{ tr.numero }}</vs-td
              >
              <vs-td
                :data="tr.id"
                class="text-center font-semibold"
                style="font-size: 12px"
                >{{ tr.id_contrato }}</vs-td
              >
              <vs-td :data="tr.cliente">
                <ModalVisualizarCliente
                  @update="getCliente()"
                  :id_cliente="tr.id_cliente"
                  fontSize="small"
                  :nomeCliente="tr.cliente"
                  textColor="dark"
                />
              </vs-td>
              <vs-td style="font-size: 10px">
                <b>{{ tr.empresa }}</b>
              </vs-td>
              <vs-td class="text-center">
                <b>{{ $currency(tr.valor_liquido) }}</b>
              </vs-td>
              <vs-td class="text-center">
                <b>{{ $currency(tr.valor_bruto) }}</b>
              </vs-td>
              <vs-td class="text-center font-semibold">{{
                tr.data_emissao ? tr.data_emissao : "Sem Data"
              }}</vs-td>
              <vs-td :data="tr.qtd_boletos" class="text-center font-semibold">{{
                tr.qtd_boletos ? tr.qtd_boletos : 0
              }}</vs-td>
              <vs-td>
                <vs-col vs-w="4">
                  <a
                    target="_blank"
                    :href="tr.link"
                    style="cursor: pointer; text-decoration: none"
                  >
                    <vs-button
                      icon="remove_red_eye"
                      radius
                      type="relief"
                      size="small"
                      color="success"
                    />
                  </a>
                </vs-col>
                <vs-col vs-w="4">
                  <Visualizar :nota="tr"></Visualizar>
                </vs-col>
                <vs-col vs-w="4">
                  <EnviarVia @update="getBoletos" :nota="tr"></EnviarVia>
                </vs-col>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- </VuePerfectScrollbar> -->
      </div>
    </div>
    <vx-card no-shadow card-background="dark">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1"
          class
        >
          <vs-button
            @click="voltar()"
            icon-pack="feather"
            line-origin="left"
            color="#fff"
            type="line"
            icon="icon-arrow-left"
            size="small"
          >
            <span style="font-size: 14px">Voltar</span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-w="5"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <span style="color: white; font-size: 14px" class="ml-10 pt-3"
            >Boletos: {{ boletosTotal }}</span
          >
        </vs-col>
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
        >
          <vs-col
            vs-w="5"
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
          >
            <vs-button
              icon="add"
              type="line"
              size="small"
              color="primary"
              @click="gerarRemessa()"
            >
              <b style="font-size: 14px">Gerar Remessas</b>
            </vs-button>
          </vs-col>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import ModalVisualizarCliente from "./../../../cliente/visualizar.vue";
import components from "@/components/default/exports.js";
import Visualizar from "./components/visualizar";
import EnviarVia from "./components/enviarVia";
import GerarRemessas from "./components/gerarRemessas";
export default {
  data() {
    return {
      tipo_pessoa: [
        { value: 1, nome: "PESSOA FÍSICA" },
        { value: 2, nome: "PESSOA JURÍDICA" }
      ],
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
        data_emissao: this.$formartData.dataFiltro(),
        id_empresa: null,
        busca: null
      },
      empresas: [],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.5,
        height: 1000
      },
      selected: [],
      notasBoletos: [],
      boletosTotal: 0
    };
  },
  methods: {
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get(`empresa`);
    //    this.filtro.id_empresa = this.empresas[0].id;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getBoletos() {
      await this.$vs.loading({ container: "#tabelaBoletos", scale: 0.6 });
      try {
        this.notasBoletos = await this.$http.post(`getBoletos`, this.filtro);
        this.boletosTotal = this.notasBoletos[this.notasBoletos.length - 1];
        this.notasBoletos = this.notasBoletos.slice(
          0,
          this.notasBoletos.length - 1
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#tabelaBoletos > .con-vs-loading");
      }
    },
    async voltar() {
      this.$router.push("/financeiro");
    },
    async ver(data) {
      this.selected[0] = [data];
    },
    // async gerarRemessa() {
    //   await this.$vs.loading({ container: "#tabelaBoletos", scale: 0.6 });
    //   try {
    //     await this.$http.post(`gerarRemessa`, this.selected);
    //   } catch (err) {
    //     const error = this.$httpErrors(err);
    //     this.$vs.notify(error);
    //   } finally {
    //     await this.$vs.loading.close("#tabelaBoletos > .con-vs-loading");
    //   }
    // },
    async gerarRemessa() {
      await this.$vs.loading({ container: "#tabelaBoletos", scale: 0.6 });
      await this.$http
        .post("gerarRemessa", this.selected)
        .then(async response => {
          if (response == null || response == "") {
            await this.$vs.notify({
              title: "Erro ao gerar Remessa",
              color: "danger"
            });
          } else if (response.fail && response.message) {
            await this.$vs.notify({
              title: response.title,
              text: response.message,
              color: "danger"
            });
          } else if (response.message == "Server Error") {
            await this.$vs.notify({
              title: "Erro: " + response.message,
              color: "danger"
            });
          } else {
            const blob = await new Blob([response], {
              type: "application/pdf"
            });
            const link = document.createElement("a");
            link.href = await URL.createObjectURL(blob);
            link.download =
              (await this.$formartData.formatar(
                new Date(this.filtro.data_emissao)
              )) + "-remessa.rem";
            await link.click();
            await URL.revokeObjectURL(link.href);
          }
        })
        .catch(console.error);
      await this.$vs.loading.close("#tabelaBoletos > .con-vs-loading");
    }
  },
  async mounted() {
    await this.getEmpresas();
    await this.getBoletos();
  },
  components: {
    ...components,
    GerarRemessas,
    Visualizar,
    EnviarVia,
    ModalVisualizarCliente
  }
};
</script>
<style lang="scss">
.scroll-boleto {
  height: 73vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
