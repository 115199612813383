<template>
  <div class="w-full">
    <vs-button
      icon="email"
      size="small"
      :disabled="!(nota.qtd_boletos > 0)"
      type="relief"
      @click="confirmarEnvio()"
      radius
      color="warning"
    />
  </div>
</template>
<script>
export default {
  props: {
    nota: { required: true }
  },
  methods: {
    async enviarVia () {
      try {
        await this.$http.post(`enviarViaBoleto`, this.nota)
        await this.$vs.notify({
          title: "Sucesso",
          text: "Email Enviado Com Sucesso!",
          color: "success"
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$emit('update')
      }
    },
    async confirmarEnvio () {
      this.modalEnviarVia = await false
      await this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: `Confirmar`,
        acceptText: 'Confirmar',
        cancelText: 'Cancelar',
        text: `Confirmar reenvio dos boletos
         da ${this.nota.numero == 'SN' ? 'NOTA S/N' : `NOTA N° ${this.nota.numero}`}?`,
        accept: await this.enviarVia
      })
    },
  }
}
</script>
<style scoped lang="scss">
.active-contato {
  background-color: #ffca00;
  transition: background 0.1s ease-in-out;
  transition: 0.4s;
  border-radius: 5px;
  font-size: 16px;
}
.contato {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  background: #eeeeee;
  &:hover {
    cursor: pointer !important;
    transition: 0.1s;
    background: #fff;
    border-width: 1px;
    border-color: black;
    transform: translateY(-3px);
  }
}
</style>
