<template>
  <div class="w-full">
    <vs-button
      icon="file_copy"
      @click="openModal()"
      size="small"
      :disabled="!(nota.qtd_boletos > 0)"
      type="relief"
      radius
      color="success"
    />

    <vs-popup
      v-if="open"
      title="Visualizar Boletos"
      :active.sync="modalVisualizar"
    >
      <div id="loadingTabelaBoletos" class="mb-1 vs-con-loading__container">
        <div v-if="boletos.length == 0">
          <div class="pt-20 scroll-boleto-visualizar con-colors">
            <ul class="pt-20">
              <li class="danger-box">
                <h2 class="p-5" style="color: white !important">
                  Nenhum Boleto
                </h2>
              </li>
            </ul>
          </div>
        </div>
        <div v-else class="mt-1">
          <VuePerfectScrollbar
            class="scroll-boleto-visualizar"
            :settings="settings"
          >
            <vs-table
              stripe
              :data="boletos"
              pagination
              max-items="10"
              id="tableCliente"
            >
              <template slot="thead">
                <vs-th sort-key="id" style="width: 2%" id="center">N°</vs-th>
                <vs-th style="width: 2%" id="center">Parcela</vs-th>
                <vs-th style="width: 5%" id="center">Valor</vs-th>
                <!-- <vs-th style="width: 7%" id="center">Emissão</vs-th> -->
                <vs-th style="width: 7%" id="center">Vencimento</vs-th>
                <vs-th style="width: 2%" id="center">Via</vs-th>
                <vs-th style="width: 3%" id="center">Visualizado</vs-th>
                <vs-th style="width: 5%" id="center">Ações</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr
                  :data="tr"
                  :key="index"
                  v-for="(tr, index) in data"
                  :style="
                    tr.visualizado > 0
                      ? 'color: green;background-color:#e8fce8 !important;'
                      : ''
                  "
                >
                  <vs-td style="font-size: 10px" class="text-center">
                    <b>{{ tr.id_conta }}</b>
                  </vs-td>
                  <vs-td style="font-size: 10px" class="text-center">
                    <b>{{ tr.parcela }}</b>
                  </vs-td>
                  <vs-td style="font-size: 10px" class="text-center">
                    <b>{{ $currency(tr.valor) }}</b>
                  </vs-td>
                  <!-- <vs-td style="font-size: 10px" class="text-center">
                    <b>{{ tr.data_emissao }}</b>
                  </vs-td> -->
                  <vs-td style="font-size: 10px" class="text-center">
                    <b>{{ tr.data_vencimento }}</b>
                  </vs-td>
                  <vs-td style="font-size: 10px" class="text-center">
                    <b>{{ tr.via }}</b>
                  </vs-td>
                  <vs-td style="font-size: 10px" class="text-center">
                    <b>{{ tr.visualizado ? "Sim" : "Não" }}</b>
                  </vs-td>
                  <vs-td class="text-center">
                    <a style="color: blue" :href="tr.link" target="_blank">
                      link
                    </a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </VuePerfectScrollbar>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    nota: {},
  },
  data () {
    return {
      open: false,
      modalVisualizar: false,
      boletos: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      app_host: `${process.env.VUE_APP_HOST}`,
    }
  },
  methods: {
    async openModal () {
      this.open = await true
      this.modalVisualizar = await true
      await this.$vs.loading({
        container: "#loadingTabelaBoletos",
        scale: 0.6,
      })
      try {
        await this.getBoletosByNota()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#loadingTabelaBoletos > .con-vs-loading")
      }
    },
    async getBoletosByNota () {
      try {
        this.boletos = await this.$http.get(`getBoletosByNota/${this.nota.id}`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
  },
  components: {
    ...components,
  },
};
</script>
<style lang="scss">
.scroll-boleto-visualizar {
  height: 55vh;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
